/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"

import { connect } from "react-redux"

import { lingua_it } from "../../i18n/it-IT"
import { lingua_en } from "../../i18n/en-GB"
import { lingua_es } from "../../i18n/es-ES"
import { lingua_fr } from "../../i18n/fr-FR"

import Header from "../header"
import Footer from "../footer"

import "../../assets/style.scss"

const Layout = props => {
  const { lang, cust, children } = props

  const showMenu = props.location.pathname.includes("tessuto")

  let lingua = {}

  switch (lang) {
    case "it-IT":
      lingua = lingua_it
      break
    case "es-ES":
      lingua = lingua_es
      break
    case "fr-FR":
      lingua = lingua_fr
      break
    case "en-GB":
      lingua = lingua_en
      break
    default:
      lingua = lingua_it
  }

  useEffect(() => {
    window.Snipcart.ready.then(function() {
      window.Snipcart.api.session.setLanguage(lingua.lang)
    })
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
    window.addEventListener("resize", () => {
      vh = window.innerHeight * 0.01

      document.documentElement.style.setProperty("--vh", `${vh}px`)
    })
  })

  return (
    <>
      <Header lingua={lingua} cust={cust} showMenu={showMenu} />
      <main>{children}</main>
      <Footer lingua={lingua} />
    </>
  )
}

function mapStateToProps(state) {
  return {
    lang: state.shop.lingua,
    cust: state.shop.customization,
  }
}

export default connect(mapStateToProps)(Layout)
