export const prices = {
  club: 0,
  contrastoColletto: 0,
  contrastoPolsino: 0,
  contrastoChiusura: 0,
  iniziali: 0,
  mascherina: 6,
};

export const owner = {
  lang: "it",
  appName: "3D Shirt",
  appUrl: "https://www.turrisicamiciesumisura.it",
  azienda: "Sartoria Turrisi",
  attenzione: "Pietro Turrisi",
  indirizzo: "Via Domenico Amati, 11",
  citta: "Ceglie Messapica",
  provincia: "BR",
  cap: "72013",
  email: "turrisicamiciesumisura@gmail.com",
  telefono: "+39 0831 377637",
  piva: "02039360744",
  registroDi: "Taranto",
  foro: "Taranto",
  gdpr: {
    titolare: "Pietro Turrisi",
    email: "turrisicamiciesumisura@gmail.com",
    data: "07 Gennaio 2020",
  },
  social: {
    facebook: "",
    twitter: "",
    whatsapp: "https://api.whatsapp.com/send?phone=393331135275",
  },
};
